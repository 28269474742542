<template>
  <article class="default-teaser" :class="{ 'default-teaser--ads-teaser': isAdvertisment }">
    <p v-if="article.attributes.teaser.preTitle && !miniteaser && !isAdvertisment" class="default-teaser__category">
      {{ article.attributes.teaser.preTitle }}
    </p>
    <p v-else-if="article.relationships.channels && !miniteaser && !isAdvertisment" class="default-teaser__category">
      {{ article.relationships.channels | getChannelName($config) }}
    </p>
    <p v-if="isAdvertisment" class="default-teaser__category">WERBUNG</p>
    <h2 v-if="article.attributes.teaser.title" class="default-teaser__title">
      <ArticleLink v-snip="3" :article="article" :title="article.attributes.teaser.title" class="default-teaser__link">
        {{ article.attributes.teaser.title }}
      </ArticleLink>
    </h2>
    <SpunqImage
      v-if="article.attributes.teaser.image"
      class="default-teaser__picture"
      :image="article.attributes.teaser.image"
      :width="310"
      :height="207"
      :mobile-width="100"
      :mobile-height="70"
      mode="crop"
      :lazy="lazy"
    />
    <LeadTextTeaser
      v-if="article.attributes.teaser.leadText && !miniteaser"
      v-snip:[leadTextSnipMethod]="screenType === 'xs' ? 8 : 4"
      class="default-teaser__leadtext"
    >
      {{ article.attributes.teaser.leadText }}
    </LeadTextTeaser>
    <footer v-if="miniteaser && !isAdvertisment" class="default-teaser__footer">
      <span class="default-teaser__date">
        {{ article.attributes.frontendDate | formatDate($config) }}
      </span>
    </footer>
    <footer
      v-else-if="!isAdvertisment"
      :class="[
        article.relationships.authors.length < 1 ? 'default-teaser__footer--noimage' : '',
        'default-teaser__footer',
      ]"
    >
      <p v-if="article.relationships.authors.length > 0 && !miniteaser" class="default-teaser__author">
        {{ article.relationships.authors[0].attributes.firstName }}
        {{ article.relationships.authors[0].attributes.lastName }}
      </p>
      <span class="default-teaser__date">
        {{ article.attributes.frontendDate | formatDate($config) }}
      </span>
    </footer>
  </article>
</template>

<script>
import SpunqImage from '@/components/ui/image/spunq-image'
import ArticleLink from '@/components/article/link'
import LeadTextTeaser from '@/components/ui/text/lead-text-teaser'

export default {
  components: {
    LeadTextTeaser,
    SpunqImage,
    ArticleLink,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    miniteaser: {
      type: Boolean,
      required: false,
      default: false,
    },
    lazy: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    isAdvertisment() {
      return this.article?.attributes?.textType === 'Werbung'
    },
    screenType() {
      return this.$store.getters['screen/screenType']
    },
    leadTextSnipMethod() {
      return this.screenType === 'xs' ? 'js' : 'css'
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';
.default-teaser {
  position: relative;

  @media all and (min-width: $screen-width-767) {
    width: 25%;
    display: flex;
    flex-direction: column;
    max-width: 310px;
    min-height: 390px;
    max-height: 390px;
    overflow: hidden;
  }

  &:hover {
    .default-teaser__picture {
      img {
        transform: scale(1.01);
      }
    }
  }

  &__category {
    color: $color-666;
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
    font-size: 13px;

    @media all and (min-width: $screen-width-767) {
      margin: 0;
      order: 2;
    }
  }

  &__title {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 10px;
    @media all and (min-width: $screen-width-767) {
      font-size: 20px;
      order: 3;
      margin-bottom: 5px;
    }
  }

  &__link {
    text-align: left;
    color: $color-text-dark-gray;
    transition: color 0.7s;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  &__picture {
    max-width: 100px;
    margin-left: 10px;
    float: right;
    transition: transform 0.7s;

    @media all and (min-width: $screen-width-767) {
      overflow: hidden;
      max-width: none;
      width: 100%;
      margin: 0 0 5px 0;
      order: 1;
      flex-shrink: 0;
    }

    img {
      transform: scale(1);
    }
  }

  &__leadtext {
    @media all and (min-width: $screen-width-767) {
      order: 4;
      margin-bottom: 5px;
    }
  }

  &__footer {
    clear: right;
    font-size: 13px;
    font-weight: 600;
    font-variation-settings: 'wght' 600;
    display: flex;
    justify-content: space-between;

    @media all and (min-width: $screen-width-767) {
      padding-top: 20px;
      padding-right: 30px;
      position: absolute;
      width: 100%;
      bottom: 0;
      order: 5;
      font-size: 12px;
      background-image: linear-gradient(
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%,
        rgba(255, 255, 255, 1) 100%
      );
      line-height: 1;
    }
    &--noimage {
      justify-content: flex-end;
    }
  }

  &__author {
    color: $color-text;
    padding-bottom: 1px;
  }

  &__date {
    color: $color-text;
  }

  &--ads-teaser {
    .default-teaser__category {
      background-color: $color-turquoise;
      color: $color-white;
      border-radius: 2px;
      padding: 0 10px;
      line-height: 20px;
      display: inline;

      @media all and (min-width: $screen-width-767) {
        width: auto;
        align-self: flex-start;
      }
    }
  }
}
</style>
