<template>
  <article class="opinion-teaser" :class="{ 'opinion-teaser--medium': mediumTeaser }">
    <p v-if="article.attributes.teaser.preTitle && !miniteaser" class="opinion-teaser__category">
      {{ article.attributes.teaser.preTitle }}
    </p>
    <p v-else-if="article.relationships.channels && !miniteaser" class="opinion-teaser__category">
      {{ article.relationships.channels | getChannelName($config) }}
    </p>
    <div>
      <h2 v-if="article.attributes.teaser.title" class="opinion-teaser__title">
        <ArticleLink
          v-snip="4"
          :article="article"
          :title="article.attributes.teaser.title"
          class="opinion-teaser__link"
        >
          {{ article.attributes.teaser.title }}
        </ArticleLink>
      </h2>
      <LeadTextTeaser v-if="article.attributes.teaser.leadText" v-snip="4" class="opinion-teaser__leadtext">
        {{ article.attributes.teaser.leadText }}
      </LeadTextTeaser>
    </div>
    <footer v-if="miniteaser || mediumTeaser" class="opinion-teaser__footer">
      <span v-if="article.relationships.authors.length > 0" class="opinion-teaser__author">
        {{ article.relationships.authors[0].attributes.firstName }}
        {{ article.relationships.authors[0].attributes.lastName }}
      </span>
    </footer>
    <footer
      v-else-if="article.relationships.authors.length > 0"
      :class="[
        article.relationships.authors.length < 1 ? 'opinion-teaser__footer--noimage' : '',
        'opinion-teaser__footer',
      ]"
    >
      <SpunqImage
        v-if="article.relationships.authors[0].attributes.teaserImage"
        class="opinion-teaser__picture"
        :image="article.relationships.authors[0].attributes.teaserImage"
        :width="100"
        :height="100"
        :mobile-width="100"
        :mobile-height="100"
        :lazy="lazy"
      />
      <p v-if="!miniteaser" class="opinion-teaser__author">
        {{ article.relationships.authors[0].attributes.firstName }}
        {{ article.relationships.authors[0].attributes.lastName }}
      </p>
    </footer>
  </article>
</template>

<script>
import SpunqImage from '@/components/ui/image/spunq-image'
import ArticleLink from '@/components/article/link'
import LeadTextTeaser from '@/components/ui/text/lead-text-teaser'

export default {
  components: {
    LeadTextTeaser,
    SpunqImage,
    ArticleLink,
  },
  props: {
    article: {
      type: Object,
      required: true,
    },
    miniteaser: {
      type: Boolean,
      required: false,
      default: false,
    },
    mediumTeaser: {
      type: Boolean,
      required: false,
      default: false,
    },
    lazy: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
}
</script>

<style lang="scss">
@import '../../../css/import/vars';
.opinion-teaser {
  position: relative;
  background: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media all and (min-width: $screen-width-767) {
    width: 25%;
    max-width: 310px;
    min-height: 390px;
    max-height: 390px;
  }

  &--medium {
    max-width: 100%;
    width: 100%;
    min-height: auto;
  }

  &__category {
    color: $color-666;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    font-size: 13px;
  }

  &__title {
    font-size: 26px;
    margin-bottom: 15px;
    font-family: $georgia;
    line-height: 110%;
    width: 100%;
    text-align: center;

    &:before {
      content: ' ';
      width: 100%;
      height: 30px;
      text-align: center;
      background-image: url('~assets/svg/icon-quote.svg');
      background-repeat: no-repeat;
      background-position: center center;
      display: block;
      margin-bottom: 1rem;
      vertical-align: text-bottom;
    }
  }

  &__link {
    text-align: center;
    color: $color-text-dark-gray;
    transition: color 0.7s;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
    }
  }

  & &__leadtext {
    text-align: center;

    @media all and (min-width: $screen-width-767) {
      margin-bottom: 20px;
    }
  }

  &__picture {
    position: relative;
    width: 100%;
    max-width: 100px;
    padding-right: 20px;
    display: none;

    &::before {
      content: '';
      position: absolute;
      bottom: -40px;
      left: -40px;
      display: block;
      width: 140px;
      height: 140px;
      border-radius: 50%;
      background-color: $blue-fountain;
    }

    img {
      position: relative;
    }

    @media all and (min-width: $screen-width-767) {
      display: block;
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    font-size: 13px;
    font-weight: 600;
    font-variation-settings: 'wght' 600;

    @media all and (min-width: $screen-width-767) {
      padding-top: 20px;
      font-size: 12px;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  &__author {
    color: $color-text;
    text-align: center;
    line-height: 17px;

    @media all and (min-width: $screen-width-767) {
      text-align: right;
    }
  }
}
</style>
